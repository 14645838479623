


















































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Recipients } from '@/types/broadcast';
import { sendBroadcast } from '@/api';
import abills from '@/store/abills';
import { abillsModule } from '@/store';

@Component({
    name: 'CreateBroadcastMessageDialog',
})
export default class CreateBroadcastMessageDialog extends Vue {
    message = '';
    dialog = false;
    to_all = false;
    headers = [
        {text: 'Группа', value: 'gid'},
        {text: 'Улица', value: 'street'},
        {text: 'Дом', value: 'building'},
        {text: 'Действия', value: 'action', sortable: false},
    ];
    recipientsArray: Recipients[] = [];
    editedIndex = -1;
    editedItem: Recipients = {
        gid: 0,
        street: '',
        building: '',
    };
    defaultItem: Recipients = {
        gid: 0,
        street: '',
        building: '',
    };

    get formTitle() {
        return this.editedIndex === -1 ? 'Добавить получателей' : 'Редактировать получателей';
    }

    get streets() {
        return abillsModule.streets;
    }

    get groups() {
        return abillsModule.groups;
    }

    get groupsObject() {
        return this.groups.reduce((acc, cur) => ({...acc, [cur.gid]: cur.name}), {});
    }

    async created() {
        await abillsModule.fetch();
    }

    @Watch('dialog')
    onDialogChanged(val: boolean) {
        val || this.closeDialog();
    }

    editItem(item: Recipients) {
        this.editedIndex = this.recipientsArray.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
    }

    deleteItem(item: Recipients) {
        const index = this.recipientsArray.indexOf(item);
        confirm('Вы уверены что хотите удалить этих получателей?') && this.recipientsArray.splice(index, 1);
    }

    closeDialog() {
        this.dialog = false;
        setTimeout(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
        }, 300);
    }

    save() {
        if (this.editedIndex > -1) {
            Object.assign(this.recipientsArray[this.editedIndex], this.editedItem);
        } else {
            this.recipientsArray.push(this.editedItem);
        }
        this.closeDialog();
    }

    initialize() {
        this.recipientsArray = [];
        this.to_all = false;
    }

    async send() {
        if (confirm('Вы уверены?')) {
            await sendBroadcast(this.message, this.recipientsArray, this.to_all);
        }
    }
}
